export const EXPORT_KEYS_INSIGHTS = [
  { title: 'Chart', field: 'chart_name' },
  { title: 'Label', field: 'label' },
  { title: 'Segment_ID', field: 'segment_id' },
  { title: 'Segment', field: 'segment_full_name' },
  { title: 'Observed', field: 'total' },
  { title: 'Composition', field: 'composition' },
  { title: 'Index', field: 'index_value' },
  { title: 'Total Households', field: 'households' },
  { title: 'Total Persons', field: 'persons' },
  { title: 'Report ID', field: 'report_id' },
  { title: 'Creation Log', field: 'creation_log' },
  { title: 'Date Range', field: 'date_range' },
  { title: 'Audience', field: 'audience' },
];

export const EXPORT_CHART_INSIGHTS = [
  { title: 'Segment', field: 'segment_full_name' },
  { title: 'Composition', field: 'composition' },
  { title: 'Index', field: 'index_value' },
];

export const ACTIVATIONS_PLATFORMS = [
  { value: 'The Trade Desk', label: 'The Trade Desk' },
  { value: 'Madhive', label: 'Madhive' },
  { value: 'DV360', label: 'DV360' },
  { value: 'Yahoo', label: 'Yahoo' },
  { value: 'Xandr', label: 'Xandr' },
  { value: 'TruAudience', label: 'TruAudience' },
  { value: 'Neustar', label: 'Neustar' },
  { value: 'Nexxen', label: 'Nexxen' },
  { value: 'Basis', label: 'Basis' },
  { value: 'Pubmatic', label: 'Pubmatic' },
  { value: 'Vistar', label: 'Vistar' },
  { value: 'simpli.fi', label: 'simpli.fi' },
  { value: 'Meta', label: 'Meta' },
];
