// ** Reducers Imports
import layout from './layout';
import navbar from './navbar';
import auth from './authentication';
import reports from './reports';
import homeData from './home-data';
import createCustomer from './create-customer';
import campaign from './campaign';
import history from './history';
import permissions from './permissions';
import performance from './performance';
import rawReport from './rawReport';
import insights from './insights';
import offlineData from './offlineData';
import users from './users';
import audience from './audience';
import segments from './segments';
import deal from './deal';
import dataMapping from './dataMapping';
import shared from './shared';
import tags from './tags';
import campaignMonitoring from './campaign-monitoring';

const rootReducer = {
  navbar,
  layout,
  auth,
  reports,
  homeData,
  createCustomer,
  campaign,
  history,
  permissions,
  performance,
  rawReport,
  insights,
  offlineData,
  users,
  audience,
  segments,
  deal,
  dataMapping,
  shared,
  tags,
  campaignMonitoring,
};

export default rootReducer;
