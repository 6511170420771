import { SvgIcon } from '@mui/material';
import React from 'react';

function EmptyGraphIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ ...sx }} width="197" height="196" viewBox="0 0 97 96" fill="none" {...props}>
      {/* <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_203_11651)">
        <path d="M78.5 96H18.5C8.558 96 0.5 87.942 0.5 78V18C0.5 8.058 8.558 0 18.5 0H78.5C88.442 0 96.5 8.058 96.5 18V78C96.5 87.942 88.442 96 78.5 96Z" fill="#F7F9FF" />
        <path d="M44 53.499C43.967 53.499 43.934 53.499 43.901 53.496C43.019 53.451 42.272 52.839 42.059 51.984L39.617 42.213L38.873 44.199C38.579 44.979 37.835 45.498 37.001 45.498H27.5C26.396 45.498 25.499 44.601 25.499 43.497C25.499 42.393 26.396 41.499 27.5 41.499H35.615L38.129 34.797C38.438 33.978 39.236 33.456 40.115 33.501C40.991 33.552 41.729 34.164 41.942 35.013L44.339 44.604L47.117 36.825C47.402 36.027 48.155 35.499 49.001 35.499H49.022C49.874 35.508 50.627 36.057 50.897 36.867L52.442 41.499H60.002C61.106 41.499 62.003 42.396 62.003 43.5C62.003 44.604 61.106 45.501 60.002 45.501H51.002C50.141 45.501 49.376 44.949 49.106 44.133L48.938 43.629L45.887 52.173C45.599 52.971 44.843 53.499 44 53.499Z" fill="#949DB4" />
        <path d="M70.4991 72.0001C69.9861 72.0001 69.4761 71.8051 69.0861 71.4151L55.1241 57.4531C54.3441 56.6731 54.3441 55.4071 55.1241 54.6241C55.9041 53.8441 57.1701 53.8441 57.9531 54.6241L71.9151 68.5861C72.6951 69.3661 72.6951 70.6321 71.9151 71.4151C71.5251 71.8051 71.0121 72.0001 70.4991 72.0001Z" fill="#BFC8DF" />
        <path d="M44 63C33.248 63 24.5 54.252 24.5 43.5C24.5 32.748 33.248 24 44 24C54.752 24 63.5 32.748 63.5 43.5C63.5 54.252 54.752 63 44 63ZM44 27.999C35.453 27.999 28.499 34.953 28.499 43.5C28.499 52.047 35.453 59.001 44 59.001C52.547 59.001 59.501 52.047 59.501 43.5C59.501 34.953 52.547 27.999 44 27.999Z" fill="#BFC8DF" />
      </g>
      <defs>
        <clipPath id="clip0_203_11651">
          <rect width="96" height="96" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
      {/* </svg> */}

    </SvgIcon>
  );
}

export default EmptyGraphIcon;
