import { json2csv } from 'json-2-csv';
import moment from 'moment-timezone';
import ExportJsonToExcel from 'js-export-excel';
import { TimeGrouping } from '../constants';
import { ACTIVATION } from '../views/OfflineData/UploadOfflineData/constants';
import { BellStatusIcon } from '../assets/svg';
import { DATE_FORMAT } from '../constants/formats';
import {
  errorAlertSx, pendingAlertSx, utilizeAlertSx, warningAlertSx,
} from './styles';

export const getTimeGrouping = (date) => {
  if (date === '1001') return TimeGrouping.WEEK;
  if (date === '0') return TimeGrouping.MONTH;
  return TimeGrouping.DAY;
};

export const getAlertIcon = (status) => {
  switch (status) {
    case 'low':
      return <BellStatusIcon sx={utilizeAlertSx} id="bell-icon-low" />;
    case 'no_data':
      return <BellStatusIcon sx={errorAlertSx} id="bell-icon-np-data" />;
    case 'pending':
      return <BellStatusIcon sx={pendingAlertSx} id="bell-icon-pending" />;
    case 'warning':
      return <BellStatusIcon sx={warningAlertSx} id="bell-icon-warning" />;
    default:
      return <BellStatusIcon id={`bell-icon-${status}`} />;
  }
};

export const getAlertText = (status) => {
  switch (status) {
    case 'low':
      return 'Low utilization';
    case 'no_data':
      return 'No Data';
    case 'pending':
      return 'Pending/ scheduled';
    case 'success':
      return 'Success';
    case 'warning':
      return 'Warning';
    default:
      return 'Warning';
  }
};

export const addCommasToNumber = (number) => {
  if (typeof number !== 'number') return '';

  return number
    .toFixed(number - Math.floor(number) > 0 ? 2 : 0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const exportToExcel = ({
  keys, data, name, columnWidths,
}) => {
  const options = {
    fileName: name,
    datas: [
      {
        sheetData: data,
        sheetName: 'sheet',
        sheetFilter: keys.map((i) => i.field),
        sheetHeader: keys.map((i) => i.title),
        columnWidths: columnWidths || [5, 12, 7, 5, 10, 70, 70, 40],
      },
    ],
  };
  const toExcel = new ExportJsonToExcel(options);
  toExcel.saveExcel();
};

export const exportToCsv = ({
  keys, data, name, options = {},
}) => {
  json2csv(data, (e, data) => {
    const csvContent = `data:text/csv;charset=utf-8,${data}`;
    const link = document.createElement('a');

    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute('download', name);

    document.body.appendChild(link);

    link.click();
    link.remove();
  }, {
    keys,
    ...options,
  });
};

export const getS3FileName = ({
  action,
  impression_segment,
  conversion_segment,
  file_format,
  partner_name,
  customer_id,
  advertiser_id,
  audience_activation_segment,
  data_type,
}) => {
  let segmentUrl = '';

  if (action === ACTIVATION) {
    segmentUrl = '/iiq_omf_dpn$0';
  } else if (impression_segment) {
    segmentUrl = `/iiq_omf_dpn$${impression_segment}`;
  } else if (conversion_segment) {
    segmentUrl = `/iiq_omf_dpn$${conversion_segment}`;
  } else if (audience_activation_segment) {
    segmentUrl = `/iiq_omf_dpn$${audience_activation_segment}`;
  }

  let formatUrl; let
    formatUrlS3;

  if (action === 'iiq_audience_activation') {
    formatUrl = `offline_map_feed/incoming/iiq_omf_feed_type$iiq_id_audience_activation/iiq_omf_pai$${customer_id?.toLowerCase()}${segmentUrl}/iiq_omf_format_id$${file_format}`;
    formatUrlS3 = `s3://partner-${partner_name?.toLowerCase()}/offline_map_feed/incoming/iiq_omf_feed_type$iiq_id_audience_activation/iiq_omf_pai$${customer_id?.toLowerCase()}${segmentUrl}/iiq_omf_format_id$${file_format}`;
  } else {
    formatUrl = `offline_map_feed/incoming/iiq_omf_feed_type$pii_${action}/iiq_omf_pai$${customer_id?.toLowerCase()}${segmentUrl}/iiq_omf_format_id$${file_format}`;
    formatUrlS3 = `s3://partner-${partner_name?.toLowerCase()}/offline_map_feed/incoming/iiq_omf_feed_type$pii_${action}/iiq_omf_pai$${customer_id?.toLowerCase()}${segmentUrl}/iiq_omf_format_id$${file_format}`;
  }
  if (action === 'attribution' && data_type === 'Impression') {
    formatUrl += '/iiq_omf_at$16';
    formatUrlS3 += '/iiq_omf_at$16';
  }
  if (action === 'activation' && advertiser_id) {
    formatUrl += `/iiq_omf_aid$${advertiser_id}`;
    formatUrlS3 += `/iiq_omf_aid$${advertiser_id}`;
  }

  return {
    formatUrl,
    formatUrlS3,
  };
};

export const prepareDateFormat = (date) => {
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const y = date.getFullYear();
  return `${y}-${m}-${d}`;
};

export function formatBytes(bytes) {
  return (bytes / 1048576).toFixed(2);
}

export const getFileName = (name) => {
  return name?.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '_');
};

export const getOptions = (object) => {
  return Object.keys(object).map((key) => ({ value: key, label: object[key] }));
};

export const getSegmentsOptions = (segments) => {
  return segments.map(({ patv_id, patv_label }) => ({
    value: patv_id, label: patv_label,
  }));
};

export const getTimeZoneDate = (date, format) => {
  const timezone = moment.tz.guess();

  return moment.utc(date).tz(timezone).format(format || DATE_FORMAT);
};

export const exportTxtFile = (data) => {
  const content = data;
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = 'domains';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const alphabet = Array.from(Array(26)).map((e, i) => i + 65).map((x) => String.fromCharCode(x));
const alphabetLength = 26;

const getNextLetter = (value) => {
  if (value >= alphabetLength) {
    return alphabet[value % alphabetLength];
  }
  return alphabet[value];
};

const getFirstLetter = (value) => {
  return value >= alphabetLength ? alphabet[Math.floor(value / alphabetLength) - 1] : '';
};

export const formatFormatPosition = (value) => {
  const nextLetter = getNextLetter(value);
  const firstLetter = getFirstLetter(value);

  return `${firstLetter}${nextLetter}`;
};

export const getNumberFromExcelLetters = (value) => {
  if (value.length === 1) return alphabet.indexOf(value) + 1;

  const firstLetterIndex = alphabet.indexOf(value[0]) + 1;
  const secondLetterIndex = alphabet.indexOf(value[1]) + 1;

  return (firstLetterIndex * alphabetLength) + secondLetterIndex;
};

export const getMTD = () => {
  const date = new Date();
  return date.getDate() - 1;
};

export const daysAgoSinceStartDate = () => {
  const date = new Date();
  const dt = new Date(date.setDate(date.getDate() - 1));
  const current = new Date(dt.getTime());
  const previous = new Date(dt.getFullYear(), 0, 1);
  return Math.ceil((current.getTime() - previous.getTime() + 1) / 86400000);
};
