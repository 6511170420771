import { SvgIcon } from '@mui/material';
import React from 'react';

function TimeLineIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '20px', ...sx }} width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M19.1667 6.66667C19.1667 7.58333 18.4167 8.33333 17.5 8.33333C17.35 8.33333 17.2084 8.31667 17.075 8.275L14.1084 11.2333C14.15 11.3667 14.1667 11.5167 14.1667 11.6667C14.1667 12.5833 13.4167 13.3333 12.5 13.3333C11.5834 13.3333 10.8334 12.5833 10.8334 11.6667C10.8334 11.5167 10.85 11.3667 10.8917 11.2333L8.76671 9.10833C8.63337 9.15 8.48337 9.16667 8.33337 9.16667C8.18337 9.16667 8.03337 9.15 7.90004 9.10833L4.10837 12.9083C4.15004 13.0417 4.16671 13.1833 4.16671 13.3333C4.16671 14.25 3.41671 15 2.50004 15C1.58337 15 0.833374 14.25 0.833374 13.3333C0.833374 12.4167 1.58337 11.6667 2.50004 11.6667C2.65004 11.6667 2.79171 11.6833 2.92504 11.725L6.72504 7.93333C6.68337 7.8 6.66671 7.65 6.66671 7.5C6.66671 6.58333 7.41671 5.83333 8.33337 5.83333C9.25004 5.83333 10 6.58333 10 7.5C10 7.65 9.98337 7.8 9.94171 7.93333L12.0667 10.0583C12.2 10.0167 12.35 10 12.5 10C12.65 10 12.8 10.0167 12.9334 10.0583L15.8917 7.09167C15.85 6.95833 15.8334 6.81667 15.8334 6.66667C15.8334 5.75 16.5834 5 17.5 5C18.4167 5 19.1667 5.75 19.1667 6.66667Z" fill="#647091" />
      {/* </svg> */}
    </SvgIcon>
  );
}

export default TimeLineIcon;
