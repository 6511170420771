import { SvgIcon } from '@mui/material';
import React from 'react';

function LeaderboardIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '20px', ...sx }} width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M3.33329 15.8333H6.66663V9.16667H3.33329V15.8333ZM8.33329 15.8333H11.6666V4.16667H8.33329V15.8333ZM13.3333 15.8333H16.6666V10.8333H13.3333V15.8333ZM1.66663 15.8333V9.16667C1.66663 8.70833 1.82982 8.31597 2.15621 7.98958C2.4826 7.66319 2.87496 7.5 3.33329 7.5H6.66663V4.16667C6.66663 3.70833 6.82982 3.31597 7.15621 2.98958C7.4826 2.66319 7.87496 2.5 8.33329 2.5H11.6666C12.125 2.5 12.5173 2.66319 12.8437 2.98958C13.1701 3.31597 13.3333 3.70833 13.3333 4.16667V9.16667H16.6666C17.125 9.16667 17.5173 9.32986 17.8437 9.65625C18.1701 9.98264 18.3333 10.375 18.3333 10.8333V15.8333C18.3333 16.2917 18.1701 16.684 17.8437 17.0104C17.5173 17.3368 17.125 17.5 16.6666 17.5H3.33329C2.87496 17.5 2.4826 17.3368 2.15621 17.0104C1.82982 16.684 1.66663 16.2917 1.66663 15.8333Z" fill="#647091" />
      {/* </svg> */}

    </SvgIcon>
  );
}

export default LeaderboardIcon;
