import { SvgIcon } from '@mui/material';
import React from 'react';

function CheckboxIconPubmatic({
  empty, fill = '#', sx, ...props
}) {
  if (empty) {
    return (
      <SvgIcon>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.30775 17.5C1.80258 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.1974 0.5 15.6923V2.30775C0.5 1.80258 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H15.6923C16.1974 0.5 16.625 0.675 16.975 1.025C17.325 1.375 17.5 1.80258 17.5 2.30775V15.6923C17.5 16.1974 17.325 16.625 16.975 16.975C16.625 17.325 16.1974 17.5 15.6923 17.5H2.30775ZM2.30775 16H15.6923C15.7692 16 15.8398 15.9679 15.9038 15.9038C15.9679 15.8398 16 15.7692 16 15.6923V2.30775C16 2.23075 15.9679 2.16025 15.9038 2.09625C15.8398 2.03208 15.7692 2 15.6923 2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V15.6923C2 15.7692 2.03208 15.8398 2.09625 15.9038C2.16025 15.9679 2.23075 16 2.30775 16Z" fill="#949DB4" />
        </svg>
      </SvgIcon>
    );
  }

  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2Z"
        fill={fill}

      // fill="#108BE2"
      />
    </SvgIcon>
  );
}

export default CheckboxIconPubmatic;
