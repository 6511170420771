import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import moment from 'moment';
import http from '../services/Http';
import { EVENT_TYPES, TAG_TYPES } from '../types/tags';
import { DATE_FORMAT } from '../constants/formats';
import { STEP_1 } from '../constants/campaign';

const basicEndingDate = moment();
basicEndingDate.add(1, 'day');
const defaultBasics = {
  name: '',
  alias: '',
  startingDate: new Date(),
  endingDate: null,
  noExpiry: true,
};

const initialState = {
  active: false,
  done: STEP_1,
  customerWasCreated: false,
  loading: false,
  errors: [],
  segments: [],
  loadingSegments: false,
  serviceFields: [],
  loadingServiceFields: false,
  defaultServiceValues: [],
  basic: defaultBasics,
  tags: [],
  macros: [],
  loadingMacros: false,
  configurations: {},
  defaultConfigurations: {},
  defaultTag: {
    linkType: TAG_TYPES.AUDIENCE_SEGMENT,
    segmentChosen: null,
    customParams: [],
    appFlyerParams: [],
    eventType: EVENT_TYPES.IMAGE_TAG,
    beaconText: '',
    apFlyerId: '',
    advertiserId: '',
  },
  alerts: {},
  alertsTypes: [],
  loadingAlertsTypes: false,
};

export const createCustomer = createAsyncThunk(
  'create-customers',
  async (values, { getState, rejectWithValue }) => {
    const {
      createCustomer: {
        macros, segments, tags,
      }, homeData: { partner },
    } = getState();
    const {
      basic, configurations, alerts, isPartnerConfiguration, partner_id,
    } = values;

    try {
      const { data } = await http.post('/customers', {
        partner: partner_id || partner,
        basic: {
          ...basic,
          endingDate: basic.endingDate && moment(basic.endingDate).format(DATE_FORMAT),
          startingDate: moment(basic.startingDate).format(DATE_FORMAT),
        },
        configurations: configurations && {
          ...configurations,
          sendToFile: configurations.sendToFile.toString(),
          serviceEnabled: configurations.serviceEnabled.toString(),
          isUsingParentBucketName: configurations.isUsingParentBucketName.toString(),
        },
        tags,
        alerts: {
          ...alerts,
          1: alerts?.[1] ? 1 : 0,
          2: alerts?.[2] ? 1 : 0,
        },
        macros,
        segments,
        isPartnerConfiguration,
      });

      return data;
    } catch (error) {
      throw rejectWithValue(error.response || error);
    }
  },
);

export const getSegments = createAsyncThunk(
  'get-segments',
  async (_, { getState }) => {
    const { partner } = getState().homeData;

    const { data } = await http.get(`/segments/partner/${partner}`);

    return data;
  },
);

export const getMacros = createAsyncThunk(
  'get-macros',
  async ({ partner_id, isAudienceActivation }, { getState }) => {
    const { partner } = getState().homeData;
    if (partner_id || partner) {
      const { data } = await http.get(`/custom-params/${partner_id || partner}`, { params: { isAudienceActivation } });

      return data;
    }
    return null;
  },
);

export const deleteCustomParam = createAsyncThunk(
  'delete-custom-param',
  async (id) => {
    const { data } = await http.delete(`/tags/custom-params/${id}`);

    return data;
  },
);

export const createCustomParam = createAsyncThunk(
  'create-custom-param',
  async (data, { getState }) => {
    const { partner } = getState().homeData;
    const res = await http.post('/tags/custom-params', { ...data, partner_id: partner });

    return res;
  },
);

export const updateCustomParams = createAsyncThunk(
  'update-custom-param',
  async (data) => {
    const res = await http.patch('/tags/custom-params', { data });

    return res;
  },
);

export const getAlertsTypes = createAsyncThunk(
  'get-alerts-types',
  async () => {
    const { data } = await http.get('/alerts/types');
    return data;
  },
);

export const getServiceFields = createAsyncThunk(
  'service-fields',
  async (partner_id, { getState }) => {
    const { partner } = getState().homeData;

    const [{ data: fields }, { data: defaultValues }] = await Promise.all([
      await http.get('/services/fields'),
      await http.get(`/services/default-values/${partner_id || partner}`),
    ]);

    return { fields, defaultValues };
  },
);

const flattenObject = (obj) => {
  const copy = { ...obj };

  Object.keys(copy).forEach((key) => {
    copy[key] = typeof copy[key] === 'object' && copy[key]?.value ? copy[key]?.value : copy[key];
  });

  return copy;
};

export const createCustomerSlice = createSlice({
  name: 'createCustomer',
  initialState,
  extraReducers: {
    [createCustomer.pending](state) {
      state.loading = true;
    },
    [createCustomer.fulfilled](state) {
      state.loading = false;
    },
    [createCustomer.rejected](state, { payload }) {
      state.loading = false;
      let messages = payload?.data?.message || [];
      if (messages && !Array.isArray(messages)) {
        messages = [messages];
      }
      state.errors = messages;
      messages.forEach((msg) => toast.error(msg, {
        duration: 5000,
      }));
    },

    [getSegments.pending](state) {
      state.loadingSegments = true;
    },
    [getSegments.fulfilled](state, { payload }) {
      state.segments = payload;
      state.loadingSegments = false;
    },
    [deleteCustomParam.pending](state) {
      state.loadingMacros = true;
    },
    [deleteCustomParam.fulfilled](state, { meta: { arg } }) {
      state.macros = state.macros.filter((item) => item.id !== arg);
      state.loadingMacros = false;
    },
    [createCustomParam.pending](state) {
      state.loadingMacros = true;
    },
    [updateCustomParams.pending](state) {
      state.loadingMacros = true;
    },
    [updateCustomParams.fulfilled](state) {
      state.loadingMacros = false;
    },
    [createCustomParam.fulfilled](state, { meta: { arg }, payload: { data } }) {
      state.macros.push({
        key: arg.param_key,
        label: arg.param_name,
        value: arg.param_value,
        type: 'custom',
        id: data,
        removable: true,
      });
      state.loadingMacros = false;
    },
    [getSegments.rejected](state) {
      state.loadingSegments = false;
    },

    [getMacros.pending](state) {
      state.loadingMacros = true;
    },
    [getMacros.fulfilled](state, { payload }) {
      if (payload?.length) state.macros = payload;
      state.loadingMacros = false;
    },
    [getMacros.rejected](state) {
      state.loadingMacros = false;
    },

    [getAlertsTypes.pending](state) {
      state.loadingAlertsTypes = true;
    },
    [getAlertsTypes.fulfilled](state, { payload }) {
      state.alertsTypes = payload;
      state.loadingAlertsTypes = false;
    },
    [getAlertsTypes.rejected](state) {
      state.loadingAlertsTypes = false;
    },
    [getServiceFields.pending](state) {
      state.loadingServiceFields = true;
    },
    [getServiceFields.fulfilled](
      state,
      { payload: { fields, defaultValues } },
    ) {
      if (fields?.length) state.serviceFields = fields;
      if (defaultValues?.length) state.defaultServiceValues = defaultValues;

      defaultValues.forEach((val) => {
        if (val.attr_type !== 'ENUM') {
          state.configurations[val.attribute_name] = val.selected_value;
        } else {
          const field = fields.find(
            (fieldItem) => fieldItem.id == val.attribute_id,
          );

          if (field) {
            const fieldValue = field.attributeValues.find(
              (attrVal) => attrVal.value === val.selected_value,
            );

            state.configurations[val.attribute_name] = fieldValue;
          }
        }
      });
      state.defaultConfigurations = { ...state.configurations };
      state.loadingServiceFields = false;
    },
    [getServiceFields.rejected](state) {
      state.loadingServiceFields = false;
    },
  },
  reducers: {
    toggleCreateCustomer(state) {
      state.active = !state.active;
      state.done = STEP_1;
    },
    setData(state, { payload: { key, data } }) {
      if (key === 'alerts') {
        const tmp = { ...data };
        for (const id in tmp) {
          if (tmp[id] === '') {
            tmp[id] = null;
          }

          if (tmp[id] != null) {
            if (['1', '2'].includes(id)) {
              tmp[id] = tmp[id] ? 1 : 0;
            } else if (['3', '4', '7'].includes(id)) {
              tmp[id] = tmp[id] / 100;
            } else {
              tmp[id] = parseInt(tmp[id]);
            }
          }
        }

        state.alerts = { ...state.alerts, ...tmp };
      } else {
        state[key] = { ...state[key], ...flattenObject(data) };
      }
    },

    addTag(state, { payload }) {
      state.tags = [...state.tags, flattenObject(payload)];
    },
    deleteTag(state, { payload: index }) {
      const copy = [...state.tags];
      copy.splice(index, 1);
      state.tags = copy;
    },
    editTag(state, { payload: { index, ...data } }) {
      state.tags = state.tags.map((tag, i) => (i === index ? { ...tag, ...data } : tag));
    },

    setMacros(state, { payload }) {
      state.macros = payload;
    },
    addMacro(state, { payload }) {
      state.macros = [...state.macros, payload];
    },
    deleteMacro(state, { payload: key }) {
      state.macros = state.macros.filter((mcr) => mcr.key !== key);
    },

    addNewSegment(state, { payload }) {
      state.segments = [...state.segments, payload];
    },
    deleteSegment(state, { payload: value }) {
      state.segments = state.segments.filter((seg) => seg.value !== value);
    },

    setDone(state, { payload }) {
      state.done = payload;
    },
    resetAll() {
      return initialState;
    },
    resetAfterTagCreation(state) {
      return {
        ...initialState,
        macros: state.macros,
        configurations: { ...state.defaultConfigurations },
      };
    },
  },
});

const { reducer, actions } = createCustomerSlice;

export const {
  setDone,
  setData,
  addTag,
  deleteTag,
  editTag,
  resetAll,
  resetAfterTagCreation,
  toggleCreateCustomer,
  setMacros,
  addMacro,
  deleteMacro,
  addNewSegment,
  deleteSegment,
} = actions;

export const segmentTypeSelector = createSelector(
  (state) => ({
    segments: state.createCustomer.segments,
    linkType: state.createCustomer.defaultTag.linkType,
  }),
  (_, type) => type,
  ({ segments }, type) => {
    const segmentItems = segments
      .filter((segment) => segment.category === type)
      .map((segment) => ({ value: segment.id, label: segment.label }));
    return segmentItems;
  },
);

export default reducer;
