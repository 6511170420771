import { SvgIcon } from '@mui/material';
import React from 'react';

function LeftIconPubmatic({
  sx, width = 8, height = 12, ...props
}) {
  return (
    <SvgIcon
      viewBox="0 0 8 12"
      fill="none"
      sx={{
        width,
        height,
        '& path': { fill: '#647091' },
        '&:hover path': { fill: '#108BE2' },
        ...sx,
      }}
      {...props}
    >
      <path d="M7.00002 0.705083C6.81319 0.517831 6.55954 0.412598 6.29502 0.412598C6.0305 0.412598 5.77685 0.517831 5.59002 0.705083L1.00002 5.29508C0.61002 5.68508 0.61002 6.31508 1.00002 6.70508L5.59002 11.2951C5.98002 11.6851 6.61002 11.6851 7.00002 11.2951C7.39002 10.9051 7.39002 10.2751 7.00002 9.88508L3.12002 5.99508L7.00002 2.11508C7.39002 1.72508 7.38002 1.08508 7.00002 0.705083Z" />
    </SvgIcon>
  );
}

export default LeftIconPubmatic;
