import { SvgIcon } from '@mui/material';
import React from 'react';

function DeleteCircleIconPubmatic({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="#108BE2" />
      {/* </svg> */}

    </SvgIcon>
  );
}

export default DeleteCircleIconPubmatic;
