import { SvgIcon } from '@mui/material';
import React from 'react';

function ColumnsIconPubmatic({ sx, ...props }) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M3.5155 16.6923V7.30775C3.5155 6.81058 3.6925 6.385 4.0465 6.031C4.4005 5.677 4.826 5.5 5.323 5.5H18.6923C19.1894 5.5 19.615 5.677 19.969 6.031C20.323 6.385 20.5 6.81058 20.5 7.30775V16.6923C20.5 17.1894 20.323 17.615 19.969 17.969C19.615 18.323 19.1894 18.5 18.6923 18.5H5.323C4.826 18.5 4.4005 18.323 4.0465 17.969C3.6925 17.615 3.5155 17.1894 3.5155 16.6923ZM5.30775 17H8.6615V7H5.30775C5.23075 7 5.16025 7.03208 5.09625 7.09625C5.03209 7.16025 5 7.23075 5 7.30775V16.6923C5 16.7692 5.03209 16.8398 5.09625 16.9038C5.16025 16.9679 5.23075 17 5.30775 17ZM10.1615 17H13.823V7H10.1615V17ZM15.323 17H18.677C18.7538 17 18.8243 16.9679 18.8885 16.9038C18.9525 16.8398 18.9845 16.7692 18.9845 16.6923V7.30775C18.9845 7.23075 18.9525 7.16025 18.8885 7.09625C18.8243 7.03208 18.7538 7 18.677 7H15.323V17Z" fill="#647091" />
      {/* </svg> */}

    </SvgIcon>
  );
}

export default ColumnsIconPubmatic;
