export const IMPRESSION_SEGMENT = 16;
export const CONVERSION_SEGMENT = 17;
export const AUDIENCE_ACTIVATION_SEGMENT = 18;
export const FIRST_PARTY_SEGMENT = 1;
export const THIRD_PARTY_SEGMENT = 3;

export const EXPORT_KEYS_SEGMENTS = [
  { title: 'Id', field: 'id' },
  { title: 'Name', field: 'name' },
  { title: 'Number of users', field: 'users_amount' },
  { title: 'End Date', field: 'end_date' },
  { title: 'Partner', field: 'partner_name' },
  { title: 'Customer', field: 'customer_id' },
  { title: 'Created date', field: 'created_date' },
  { title: 'Created by', field: 'created_by_email' },
];
