import { skin, SKINS } from './skin';

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_NESTJS_API_AUDIENCE;

export const AUTH0_CLIENT_ID = skin === SKINS.IIQ
  ? process.env.REACT_APP_AUTH0_BE_APP_CLIENT_ID
  : process.env.REACT_APP_AUTH0_DATONICS_APP_CLIENT_ID;

export const AUTH0_REDIRECT_URL = skin === SKINS.IIQ
  ? process.env.REACT_APP_AUTH0_REDIRECT_URL
  : process.env.REACT_APP_AUTH0_DATONICS_REDIRECT_URL;

export const AUTH0_LOGOUT_URL = skin === SKINS.IIQ
  ? process.env.REACT_APP_AUTH0_LOGOUT_URL
  : process.env.REACT_APP_AUTH0_DATONICS_LOGOUT_URL;
