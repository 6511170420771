import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import {
  arrowUpSx, closeIconSx, containerSx, hintSx, successContainerSx,
} from './styles';
import { CloseIcon, InfoIcon } from '../../assets/svg';

export const toastError = (text, options) => toast((t) => (
  <Box sx={containerSx} id="toast-error">
    <CloseIcon onClick={() => toast.dismiss(t.id)} sx={closeIconSx} id="toast-error-close-icon" />

    {text}
    <Box sx={arrowUpSx} />
  </Box>
), {
  duration: 30000, position: 'bottom-right', id: 'error', ...options,
});

export const toastErrorCreateFormat = (text, options) => toast((t) => (
  <Box sx={containerSx} id="toast-error">
    <CloseIcon onClick={() => toast.dismiss(t.id)} sx={closeIconSx} id="toast-error-close-icon" />

    {text}
    <Box sx={arrowUpSx} />

    <Box sx={hintSx}>
      <InfoIcon sx={{ mr: '5px' }} />
      <Box>
        Check the allowed formats
        {'  '}
        <a target="_blank" href="https://cda.documents.intentiq.com/crm-data-upload/files-uploader#id-5.1.3-new-format-validation" rel="noreferrer">here</a>
        {/**/}
        .
      </Box>
    </Box>
  </Box>
), {
  duration: 30000, position: 'bottom-right', id: 'error', ...options,
});

export const toastSuccess = (text, options) => toast(() => (
  <Box sx={successContainerSx} id="toast-success">
    {text}
    <Box sx={arrowUpSx} />
  </Box>
), {
  duration: 5000, position: 'bottom-right', id: 'success', ...options,
});

export const toastErrorDatonics = (text, options) => toast((t) => (
  <Box sx={containerSx} id="toast-error">
    <CloseIcon onClick={() => toast.dismiss(t.id)} sx={closeIconSx} id="toast-error-close-icon" />

    {text}
  </Box>
), {
  duration: 30000, position: 'top-right', id: 'error', ...options, style: { marginTop: '100px', marginRight: '44px' },
});

export const toastSuccessDatonics = (text, options) => toast(() => (
  <Box sx={successContainerSx} id="toast-success">
    {text}
  </Box>
), {
  duration: 5000, position: 'top-right', id: 'success', ...options, style: { marginTop: '100px', marginRight: '44px' },
});
