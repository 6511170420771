export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_SLASH = 'YYYY/MM/DD';
export const DATE_FORMAT_PRIMARY = 'MM.DD.YY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const DATE_FORMAT_SECONDARY = 'MM.DD.YY';
export const DATE_FORMAT_SECONDARY_SLASH = 'MM/DD/YY';
export const DATE_FORMAT_THIRD = 'll';
export const TIME_FORMAT = 'HH:mm';
export const DEFAULT_TZ = 'Israel';
export const TABLE_DATE_TIME_FORMAT = 'MM/DD/YYYY | HH:mm';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
