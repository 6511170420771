import { SvgIcon } from '@mui/material';
import React from 'react';

function DonutIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '20px', ...sx }} width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M10 17.9167C8.90601 17.9167 7.87747 17.7086 6.91442 17.2925C5.95122 16.8764 5.1133 16.312 4.40067 15.5994C3.68803 14.8867 3.12365 14.0488 2.70754 13.0856C2.29143 12.1226 2.08337 11.094 2.08337 9.99999C2.08337 8.20512 2.61893 6.60686 3.69004 5.20519C4.76101 3.80339 6.16567 2.86589 7.904 2.39269C8.18914 2.29644 8.44976 2.33304 8.68587 2.50249C8.92199 2.67179 9.04004 2.89908 9.04004 3.18436C9.04004 3.382 8.98428 3.56068 8.87275 3.7204C8.76108 3.88013 8.61761 3.98825 8.44233 4.04478C7.08233 4.38242 5.97629 5.10304 5.12421 6.20665C4.27212 7.31026 3.84608 8.57471 3.84608 9.99999C3.84608 11.7104 4.44414 13.1637 5.64025 14.3598C6.83636 15.5559 8.28962 16.1539 10 16.1539C10.8291 16.1539 11.6258 15.9969 12.3902 15.6827C13.1547 15.3685 13.8168 14.9219 14.3767 14.3429C14.5263 14.1965 14.705 14.1207 14.9127 14.1154C15.1205 14.11 15.3018 14.1751 15.4567 14.3108C15.68 14.5064 15.7959 14.7361 15.8044 15C15.813 15.2639 15.703 15.5 15.4744 15.7083C14.7297 16.4305 13.8942 16.9792 12.968 17.3542C12.0417 17.7292 11.0524 17.9167 10 17.9167ZM16.154 9.99999C16.154 8.58332 15.7232 7.32179 14.8615 6.2154C13.9998 5.10915 12.8916 4.38561 11.5369 4.04478C11.3564 3.98825 11.2102 3.88013 11.0986 3.7204C10.9869 3.56068 10.9311 3.382 10.9311 3.18436C10.9311 2.89908 11.0505 2.67311 11.2892 2.50644C11.5281 2.33978 11.7901 2.30186 12.0752 2.39269C13.8061 2.85311 15.2123 3.7895 16.294 5.20186C17.3758 6.61422 17.9167 8.2136 17.9167 9.99999C17.9167 10.3248 17.8969 10.6464 17.8573 10.9648C17.8179 11.2831 17.7516 11.6058 17.6586 11.9327C17.6052 12.2126 17.4578 12.4126 17.2163 12.5329C16.9749 12.653 16.7143 12.6608 16.4344 12.556C16.2452 12.4856 16.1018 12.3593 16.004 12.1771C15.9062 11.995 15.8798 11.8024 15.9248 11.5994C15.9984 11.2992 16.055 11.0188 16.0946 10.7581C16.1342 10.4974 16.154 10.2447 16.154 9.99999Z" fill="#647091" />
      {/* </svg> */}

    </SvgIcon>
  );
}

export default DonutIcon;
