import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import moment from 'moment';
import http from '../services/Http';
import { DEFAULT_ERROR } from '../constants/errors';
import { tableDefaultState } from '../constants';
import { exportToCsv } from '../utility';

const initialState = {
  table: tableDefaultState,
  tablePreview: {
    columns: [],
    list: [],
    total: 0,
  },
  data: [],
  loading: false,
  total: 0,
  page: 1,
  isTableLoading: false,
  monitoringLoading: false,
  error: null,
  totals: {},
};

export const getMonitoringReports = createAsyncThunk(
  'pubmatic',
  async ({
    page, perPage, filters,
  }) => {
    if (!filters.partner) return [];

    const { data } = await http.get('/pubmatic', {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });

    return data;
  },
);

export const getMonitoringReportSummary = createAsyncThunk(
  'pubmatic/summary',
  async ({
    partner, customer, filters,
  }) => {
    const { data } = await http.get('/pubmatic/summary', {
      params: {
        partner, customer, filters,
      },
    });

    return data;
  },
);

export const getMonitoringReportsCsv = createAsyncThunk(
  'pubmatic/csv',
  async ({ filters, columns }) => {
    if (!filters.partner) return [];

    const { data } = await http.get('/pubmatic', {
      params: {
        ...filters,
      },
    });

    const formattedData = data[0].map((row) => {
      const formattedRow = {
        ...row,
        date: moment(row.date).format('MM/DD/YYYY'),
      };

      if (row.hour) {
        formattedRow.hour = moment(row.hour).format('HH-mm');
      }

      return formattedRow;
    });

    const keys = columns.map((item) => ({
      title: item.name,
      field: item.id,
    }));

    exportToCsv({
      name: 'pubmatic_report.csv',
      data: formattedData,
      keys,
    });
  },
);

export const getDeals = createAsyncThunk('pubmatic/deals',
  async ({ partner_id, customer_id }, { rejectWithValue }) => {
    try {
      const response = await http.get('pubmatic/deals', {
        params: { partner_id, customer_id },
      });
      return response.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const getFilterOptions = createAsyncThunk(
  'pubmatic/filter-options',
  async ({ deals, field }, { rejectWithValue }) => {
    try {
      const response = await http.get('pubmatic/filter-options', { params: { deals, field } });
      return { field, data: response.data };
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

const campaignMonitoringSlice = createSlice({
  name: 'campaignMonitoring',
  initialState,
  reducers: {},
  extraReducers: {
    [getMonitoringReports.pending]: (state) => {
      state.table.loading = true;
    },
    [getMonitoringReports.rejected]: (state) => {
      state.table.loading = false;
    },
    [getMonitoringReports.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.table = {
        ...state.table,
        loading: false,
        page: arg.page || state.table.page,
        data: payload[0],
        total: payload[1],
      };
    },
    [getMonitoringReportSummary.pending]: (state) => {
      state.isTableLoading = true;
    },
    [getMonitoringReportSummary.rejected]: (state) => {
      state.isTableLoading = false;
    },
    [getMonitoringReportSummary.fulfilled]: (state, { payload }) => {
      state.isTableLoading = false;
      state.totals = payload;
    },
  },
});

export default campaignMonitoringSlice.reducer;
